import React from 'react';
import Layout from '../layouts/Layout';
import JobAlerts from '../components/JobAlerts/JobAlerts';
import arrow from '../images/arrow.svg';
const JobDescription = ({ pageContext }: any) => {
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Job Opportunities',
			href: '/search/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Worldwide Opportunities',
			href: 'https://www.boydcorp.com/company/about-us/career-opportunities.html',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={`${pageContext.title} | Boyd Careers`}
			desc={`${pageContext.title} | Boyd Careers`}
		>
			<section className={'bg-sec py-6'}>
				<div className={'container mx-auto px-5'}>
					<a
						href={'/search/'}
						className={
							'mb-24 flex max-w-[100px] flex-row font-sans text-white hover:text-white hover:underline'
						}
					>
						<img
							src={arrow}
							width={16}
							className={'mr-3 rotate-180'}
							alt={'Back Arrow.'}
						/>
						Back
					</a>
					<h1
						className={'font-sans text-[42px] font-bold text-white'}>
						{pageContext.title}
					</h1>
				</div>
			</section>
			<section
				className={
					'bg-[linear-gradient(0deg, #FFFFFF 0%, #DCE8E3 100%)] px-3 pb-14 md:py-14'
				}
			>
				<div className={'container mx-auto'}>
					<div
						className={
							'mt-6 flex flex-row justify-between max-lg:flex-col-reverse'
						}
					>
						<div
							className={
								'job-description-content flex w-3/4 flex-col max-lg:w-full'
							}
						>
							<h2
								className={
									'mb-6 font-sans text-3xl font-bold text-pri'
								}
							>
								Job Description
							</h2>
							<p
								dangerouslySetInnerHTML={{
									__html: pageContext.description
								}}
							/>
							<a
								href={pageContext.url}
								className={'mt-6 max-w-[300px]'}
								target={'_blank'}
							>
								<button
									className={
										'flex flex-row items-center rounded-xl bg-accent p-3 px-10 text-center text-white hover:bg-accent/[0.9] focus:bg-accent/[0.9] ApplyButton ApplyButtonTop'
									}
								>
									<span className={'font-sans text-xl'}>
										Apply Now
									</span>
								</button>
							</a>
						</div>
						<div
							className={
								'flex w-1/4 flex-col px-3 pb-6 max-lg:w-full max-md:px-0'
							}
						>
							<div
								className={
									'mt-6 rounded bg-pri p-6 shadow-xl'
								}
							>
								<span className={'font-sans text-white text-xl font-bold'}>
									Location:
								</span>
								<p className={'pb-6 font-sans text-white text-lg'}>
									{pageContext.city}, {pageContext.state}
								</p>
								<span className={'font-sans text-white text-xl font-bold'}>
									Req ID:
								</span>
								<p className={'pb-6 font-sans text-white text-lg'}>
									{pageContext.reqNumber}
								</p>
								<a
									href={pageContext.url}
									className={'ApplyButton ApplyButtonBottom'}
									target={'_blank'}
								>
									<button
										className={
											'flex w-full flex-row items-center justify-center rounded-xl bg-accent p-3 text-center text-white hover:bg-accent/[0.9] focus:bg-accent/[0.9]'
										}
									>
										<span className={'font-sans text-xl'}>
											Apply Now
										</span>
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<JobAlerts />
		</Layout>
	);
};

export default JobDescription;
